import './App.scss'
import Nav from 'react-bootstrap/Nav'
import Dropdown from 'react-bootstrap/Dropdown'
import Navbar from 'react-bootstrap/Navbar'
import {BrowserRouter, HashRouter, Link} from 'react-router-dom'
import {Route, Switch} from 'react-router'

function App() {
  return (
    <div className="App bg-dark">
      <Navbar bg="dark shadow" expand="lg" variant="dark" className="text-light">
        <Navbar.Brand href="#home">indoqa. test-app</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="/web/">About</Nav.Link>
            <Nav.Link href="/web/docs" className="mr-2">Docs</Nav.Link>
          </Nav>
          <Dropdown align="right">
            <Dropdown.Toggle variant="primary" size="sm" id="dropdown-basic">
              <i className="bi bi-user" />Matthias
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar.Collapse>
      </Navbar>
      <header className="App-header text-center text-light" style={{height: '100vh'}}>
        <BrowserRouter>
          <Switch>
            <Route path={'/feature1'}>
              <div className="p5 mt-5"> Das ist ein deeplink auf feature1</div>
            </Route>
            <Route path={'/feature2'}>
              <div className="p5 mt-5"> Das ist ein deeplink auf feature2</div>
            </Route>
            <Route>
              <div className="p5 mt-5"> Das ist die react single page app</div>
            </Route>
          </Switch>
          <div className="mt-5">
            <Link to="/"> Home </Link>
            <Link to="/feature1"> feature1 </Link>
            <Link to="/feature2"> feature2 </Link>
          </div>
        </BrowserRouter>
      </header>
    </div>
  )
}

export default App
